import 'swiper/swiper-bundle.css';
import '../sdist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss'

import Swiper, { Navigation, Pagination, Thumbs} from 'swiper';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

import * as classes from './editor.scss';
import * as classes from './Draftail/CommentApp/main.scss';
import CommentableEditor from './Draftail/CommentableEditor';
import { Provider } from 'react-redux';
import './Draftail/comments'


import React from "react"
import ReactDOM from "react-dom"

import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE  } from "draftail"


const plugin = {
  initEditor(selector, options, currentScript) {

    const field = document.body.querySelector(selector);

    const editorWrapper = document.createElement('div');
    editorWrapper.className = 'Draftail-Editor__wrapper';
    editorWrapper.setAttribute('data-draftail-editor-wrapper', true);

    field.parentNode.appendChild(editorWrapper);

    const rawContentState = JSON.parse(field.value);

    const serialiseInputValue = rawContentState => {
      field.rawContentState = rawContentState;
      field.value = JSON.stringify(rawContentState);
    };

    const editorRef = React.createRef();
    const contentPath = window.comments?.getContentPath(field) || '';

    const colors = {
      standardHighlight: '#D5CEF3',
      overlappingHighlight: '#E5E2F9',
      focusedHighlight: '#473786'
    };

    const editor = (
      <Provider store={window.comments.commentApp.store}>
        <CommentableEditor
          editorRef={editorRef}
          commentApp={window.comments.commentApp}
          contentPath={contentPath}
          colorConfig={colors}
          fieldNode={field.parentNode}
          isCommentShortcut={window.comments.isCommentShortcut}
          rawContentState={rawContentState}
          onSave={serialiseInputValue}
          blockTypes={[
            { type: BLOCK_TYPE.UNSTYLED },
            { type: BLOCK_TYPE.HEADER_THREE },
            { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
            { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
            { type: BLOCK_TYPE.BLOCKQUOTE },

          ]}
          inlineStyles={[
            { type: INLINE_STYLE.BOLD }, { type: INLINE_STYLE.ITALIC },
            { type: INLINE_STYLE.UNDERLINE }, { type: INLINE_STYLE.MARK },
          ]}
          entityTypes={[
            {
              type: ENTITY_TYPE.LINK,
              icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
              </svg>,
              // [...]
            },
          ]}
        />
      </Provider>
    )

    ReactDOM.render(editor, editorWrapper)
  }
}

window.draftail = plugin;

document.addEventListener('alpine:init', () => {
  Alpine.data('toggleExtraFieldChecked', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }));
});

Alpine.plugin(collapse);
Alpine.start();
